.title{
    color: aliceblue;
    font-size: 320%;
    font-family: monospace;
    font-weight: bold;
    margin-left: 15vh;
    margin-top: 8vh;
}

.text{
    color: aliceblue;
    font-size: 120%;
    margin-left: 2.6vh;
    margin-right: 1vh;
}

.button{
    background-color: white;
    color: #3e208a;
    font-size: 150%;
    font-family: monospace;
    margin-top: 0.5vh;
    margin-left: 2.6vh;
}

.pause{
    background-color: red;
}

.withdraw{
    margin-bottom: 5vh;
}

.input-field{
    margin-left: 2.6vh;
    border-radius: 0%;
    width: 29.2vw;
    min-width: 510px;
}

.input-field-2{
    margin-left: 2.6vh;
    border-radius: 0%;
    max-width: 36vw;
    margin-top: -5px;
}

.input-area{
    background-image: url('NFT\ BG.png');
    background-size: cover;
    height: 100vh;
    min-height: 1080px;
}

body{
    min-width: 1920px;
}

.left{
    /* background-color: black; */
    min-width: 58vh;
    max-width: 65.5vh;
    height: 100vh;
}

.underline{
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 1px solid black;
    width: 30.5vh;
    margin-right: 1vh;
}

.contractAddr{
    color: aliceblue;
    margin-bottom: 2.6vh;
}

hr {
	border-top: 1px solid #4d4b529f;
}

.warn {
    color: yellow;
    font-size: 110%;
    margin-left: 2.6vh;
}